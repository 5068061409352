import Logo from './../img/logo.png'
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router'
import ProductoImg from './../img/motor.png'
import Descarga from './../img/descarga.jpg'
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination, Navigation, FreeMode } from "swiper";

export function Detalle() {

    const navigate = useNavigate()

    const handleLogin = () => {
        navigate('/Login')
    }
    const handleTienda = () => {
        navigate('/Vista-Tienda')
    }
    const handleInicio = () => {
        navigate('/')
    }

    return (
        <>
            <div className="bg-primario w-100 header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                                <img src={Logo} className="logo" />
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <a onClick={handleInicio} className="nav-link" href="#">
                                            <i className="fas fa-house"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={handleTienda} className="nav-link active">
                                            Tienda
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={handleLogin}>
                                            Login
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='cardd p-4'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <img src={ProductoImg} className="img-fluid" />
                        </div>
                        <div className='col-md-8'>
                            <h3>Apple iPhone 11 (64GB, Black)</h3>
                            <h5 className="item-price">$669.99</h5>
                            <p className='descripcion mt-4'>
                                The Apple iPhone 11 is a great smartphone, which was loaded with a lot of quality features. It comes with a waterproof and dustproof body which is the key attraction of the device. The excellent set of cameras offer excellent images as well as capable of recording crisp videos. However, expandable storage and a fingerprint scanner would have made it a perfect option to go for around this price range.
                            </p>
                            <button onClick={handleLogin} className='btn btn-primary bg-primario comprar mt-3'>
                                Comprar Ahora
                            </button>
                        </div>
                    </div>
                </div>
                <div className='row pb-5'>
                    <div className='col-12 mb-5'>
                        <h2 className='text-center mt-5'>
                            Productos Relacionados
                        </h2>
                        <Swiper
                            style={{
                                "--swiper-pagination-color": "#0aa5cd",
                                "--swiper-pagination-bullet-inactive-color": "#999999",
                                "--swiper-pagination-bullet-inactive-opacity": "1",
                                "--swiper-pagination-bullet-size": "12px",
                                "--swiper-pagination-bullet-horizontal-gap": "4px",
                            }}
                            slidesPerView={1}
                            spaceBetween={30}
                            slidesPerGroup={1}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination]}
                            autoplay={{
                                delay: 3500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                            }}
                            className="mySwiperInicioProduct"
                        >
                            <SwiperSlide>
                                <div className='card w-100'>
                                    <a href='#'>
                                        <img src={ProductoImg} className='' />
                                    </a>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-end fw-bold'>
                                                $ 339.99
                                            </h6>
                                        </div>
                                        <a href='#'>
                                            <h5 className="text-start">
                                                Titulo del producto
                                            </h5>
                                        </a>
                                        <p className="text-start">
                                            una breve descripcion del producto a precentar
                                        </p>
                                    </div>
                                    <div className='verProducto w-100 pt-2 pb-2 text-white '>
                                        Ver Producto
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card w-100'>
                                    <a href='#'>
                                        <img src={Descarga} className='' />
                                    </a>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-end fw-bold'>
                                                $ 339.99
                                            </h6>
                                        </div>
                                        <a href='#'>
                                            <h5 className="text-start">
                                                Titulo del producto
                                            </h5>
                                        </a>
                                        <p className="text-start">
                                            una breve descripcion del producto a precentar
                                        </p>
                                    </div>
                                    <div className='verProducto w-100 pt-2 pb-2 text-white '>
                                        Ver Producto
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card w-100'>
                                    <a href='#'>
                                        <img src={ProductoImg} className='' />
                                    </a>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-end fw-bold'>
                                                $ 339.99
                                            </h6>
                                        </div>
                                        <a href='#'>
                                            <h5 className="text-start">
                                                Titulo del producto
                                            </h5>
                                        </a>
                                        <p className="text-start">
                                            una breve descripcion del producto a precentar
                                        </p>
                                    </div>
                                    <div className='verProducto w-100 pt-2 pb-2 text-white '>
                                        Ver Producto
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card w-100'>
                                    <a href='#'>
                                        <img src={ProductoImg} className='' />
                                    </a>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-end fw-bold'>
                                                $ 339.99
                                            </h6>
                                        </div>
                                        <a href='#'>
                                            <h5 className="text-start">
                                                Titulo del producto
                                            </h5>
                                        </a>
                                        <p className="text-start">
                                            una breve descripcion del producto a precentar
                                        </p>
                                    </div>
                                    <div className='verProducto w-100 pt-2 pb-2 text-white '>
                                        Ver Producto
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className='footer mt-5'>
                <a href='www.alucinamkt.com'>
                    <p className='m-0 '>
                        © 2022 — Derechos reservados  AlucinaMKT
                    </p>
                </a>
            </div>
        </>
    )
}