import Logo from './../../img/logo.png'
import ProductoImg from './../../img/motor.png'
import Descarga from './../../img/descarga.jpg'
import BannerUno from './../../img/bannerUno.jpg'
import BannerDos from './../../img/bannerDos.jpeg'
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate, useParams } from 'react-router'
import { useAuth } from '../../authContext/authContext'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";

import { EffectFade, Autoplay, Pagination, Navigation, FreeMode } from "swiper";

export function InicioLogin() {

    const navigate = useNavigate()
    const { login } = useAuth();


    const [botoncerrar, setBotoncerrar] = useState(false);

    const handleCerrarsesion = () => {
        navigate('/')
    }

    const handleTienda = () => {
        navigate('/Tienda')
    }
    const handleCarrito = () =>{
        navigate('/Carrito')
    }

    return (
        <>
            <div className="bg-primario w-100 header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                                <img src={Logo} className="logo" />
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <a className="nav-link active">
                                            <i className="fas fa-house"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={handleTienda}>
                                            Tienda
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={handleCarrito}>
                                            <span class="position-absolute translate-middle badge rounded-pill bg-danger">
                                                99
                                            </span>
                                            <i className="fas fa-shopping-bag"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={() => { setBotoncerrar(!botoncerrar) }}>
                                            <i className="fas fa-user"></i>
                                        </a>
                                        {
                                            botoncerrar === true ?
                                                (
                                                    <div className='cerrarsesionmodal' onClick={handleCerrarsesion}>
                                                        <p className='m-0'>
                                                            Cerrar Sesión
                                                        </p>
                                                    </div>
                                                ) :
                                                <></>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <Swiper
                effect={"fade"}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation, EffectFade,]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={BannerUno} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={BannerDos} />
                </SwiperSlide>
            </Swiper>
            <div className='filtroInicio w-100'>
                <div className='container bg-primario p-5'>
                    <form>
                        <div className='row'>
                            <div className="col-md-4">
                                <select className="form-select mb-4" aria-label="Default select example">
                                    <option selected>Provincia</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <select className="form-select mb-4" aria-label="Default select example">
                                    <option selected>Ciudad</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <select className="form-select mb-4" aria-label="Default select example">
                                    <option selected>Marca</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-4">
                                <select className="form-select mb-4" aria-label="Default select example">
                                    <option selected>Modelo</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <select className="form-select mb-4" aria-label="Default select example">
                                    <option selected>Categoria</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <button type="submit" className="btn btn-primary w-100 mb-4">Buscar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='text-center fw-bold mb-4'>
                            Lo mas nuevo
                        </h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Swiper
                            style={{
                                "--swiper-pagination-color": "#0aa5cd",
                                "--swiper-pagination-bullet-inactive-color": "#999999",
                                "--swiper-pagination-bullet-inactive-opacity": "1",
                                "--swiper-pagination-bullet-size": "12px",
                                "--swiper-pagination-bullet-horizontal-gap": "4px"
                            }}
                            slidesPerView={1}
                            spaceBetween={30}
                            slidesPerGroup={1}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination]}

                            breakpoints={{
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                            }}
                            className="mySwiperInicioProduct"
                        >
                            <SwiperSlide>
                                <div className='card w-100'>
                                    <a href='#'>
                                        <img src={ProductoImg} className='' />
                                    </a>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-end fw-bold'>
                                                $ 339.99
                                            </h6>
                                        </div>
                                        <a href='#'>
                                            <h5 className="text-start">
                                                Titulo del producto
                                            </h5>
                                        </a>
                                        <p className="text-start">
                                            una breve descripcion del producto a precentar
                                        </p>
                                    </div>
                                    <div className='d-flex loginapp'>
                                        <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Comprar
                                        </div>
                                        <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Ver Producto
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card w-100'>
                                    <a href='#'>
                                        <img src={ProductoImg} className='' />
                                    </a>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-end fw-bold'>
                                                $ 339.99
                                            </h6>
                                        </div>
                                        <a href='#'>
                                            <h5 className="text-start">
                                                Titulo del producto
                                            </h5>
                                        </a>
                                        <p className="text-start">
                                            una breve descripcion del producto a precentar
                                        </p>
                                    </div>
                                    <div className='d-flex loginapp'>
                                        <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Comprar
                                        </div>
                                        <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Ver Producto
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card w-100'>
                                    <a href='#'>
                                        <img src={ProductoImg} className='' />
                                    </a>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-end fw-bold'>
                                                $ 339.99
                                            </h6>
                                        </div>
                                        <a href='#'>
                                            <h5 className="text-start">
                                                Titulo del producto
                                            </h5>
                                        </a>
                                        <p className="text-start">
                                            una breve descripcion del producto a precentar
                                        </p>
                                    </div>
                                    <div className='d-flex loginapp'>
                                        <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Comprar
                                        </div>
                                        <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Ver Producto
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card w-100'>
                                    <a href='#'>
                                        <img src={ProductoImg} className='' />
                                    </a>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-end fw-bold'>
                                                $ 339.99
                                            </h6>
                                        </div>
                                        <a href='#'>
                                            <h5 className="text-start">
                                                Titulo del producto
                                            </h5>
                                        </a>
                                        <p className="text-start">
                                            una breve descripcion del producto a precentar
                                        </p>
                                    </div>
                                    <div className='d-flex loginapp'>
                                        <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Comprar
                                        </div>
                                        <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Ver Producto
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card w-100'>
                                    <a href='#'>
                                        <img src={ProductoImg} className='' />
                                    </a>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-end fw-bold'>
                                                $ 339.99
                                            </h6>
                                        </div>
                                        <a href='#'>
                                            <h5 className="text-start">
                                                Titulo del producto
                                            </h5>
                                        </a>
                                        <p className="text-start">
                                            una breve descripcion del producto a precentar
                                        </p>
                                    </div>
                                    <div className='d-flex loginapp'>
                                        <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Comprar
                                        </div>
                                        <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Ver Producto
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card w-100'>
                                    <a href='#'>
                                        <img src={ProductoImg} className='' />
                                    </a>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-end fw-bold'>
                                                $ 339.99
                                            </h6>
                                        </div>
                                        <a href='#'>
                                            <h5 className="text-start">
                                                Titulo del producto
                                            </h5>
                                        </a>
                                        <p className="text-start">
                                            una breve descripcion del producto a precentar
                                        </p>
                                    </div>
                                    <div className='d-flex loginapp'>
                                        <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Comprar
                                        </div>
                                        <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                            Ver Producto
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className='footer mt-5'>
                <a href='www.alucinamkt.com'>
                    <p className='m-0 '>
                        © 2022 — Derechos reservados  AlucinaMKT
                    </p>
                </a>
            </div>
        </>
    )
}