import Logo from './../img/logo.png'
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router'
import db, {auth} from "../firebase/firebase"
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore, doc, collection, setDoc, getDoc, getDocs } from "firebase/firestore";
import Modal from 'react-modal';
import { useAuth } from '../authContext/authContext'





export function Registro() {

    const { logout, user } = useAuth();
    console.log(user)
    const handleCerrarsesion = async () => {
        try{
            await logout();
        }catch (error){
            console.error(error.message)
        }
    }

    const [modal, setModal] = useState(false);
    const [modalexiste, setModalexiste] = useState(false);
    const [modalfalta, setModalfalta] = useState(false);
    const [modaldigitos, setModaldigitos] = useState(false);
    const [listusu, setListusu] = useState([]);

    //firebase registro

    async function asyncCall(){
        const colRef = collection(db, "usuarios");
        const docsSnap = await getDocs(colRef);
        docsSnap.forEach(doc => {
            listusu.push(doc.data())
        });
    }
    asyncCall();

    async function registrarUsuario(email, password, user, telefono, dni, rol) {
        const infoUsuario = await createUserWithEmailAndPassword(
            auth,
            email,
            password
        ).then((usuarioFirebase) => {
            return usuarioFirebase;
        });

        console.log(infoUsuario.user.uid);
        const docuRef = doc(db, `usuarios/${infoUsuario.user.uid}`);
        setDoc(docuRef, { email: email, password: password, user: user, telefono: telefono, dni: dni, rol: rol });
        const handleCerrarsesion = async () => {
            try{
                await logout();
            }catch (error){
                console.error(error.message)
            }
        }
        handleCerrarsesion();
        setModal(!modal)
    }

    function handlerRegistro(e) {
        e.preventDefault();

        const email = e.target.elements.email.value;
        const password = e.target.elements.password.value;
        const user = e.target.elements.user.value;
        const telefono = e.target.elements.telefono.value;
        const dni = e.target.elements.dni.value;
        const rol = "Cliente";



        if(email.length == 0  || password.length == 0 || user.length == 0 || telefono.length == 0 || dni.length == 0 || rol.length == 0){
                setModalfalta(!modalfalta)
        }else{
            if(password.length < 6){
                setModaldigitos(!modaldigitos)
            }else{
                listusu.filter(e => {
                    if(e.telefono == telefono || e.email == email || e.dni == dni){
                        setModalexiste(!modalexiste)
                    }else{
                        registrarUsuario(email, password, user, telefono, dni, rol);
                        setModal(!modal)
                    }
                })
            }
        }


    }


    const navigate = useNavigate()

    const handleLogin = () => {
        navigate('/Login')
    }

    const handleInicio = () => {
        navigate('/')
    }
    const handleTienda = () => {
        navigate('/Vista-Tienda')
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };




    return (
        <>
            <Modal
                isOpen={modal}
                style={customStyles}
            >
                <div className='Modal'>
                    <h2 className='c-primario text-center'>Registro Exitoso</h2>
                    <button onClick={handleLogin} className='btn btn-primary pt-2 pb-2 p-4 m-auto d-block mt-4'>Seguir</button>
                </div>
            </Modal>
            <Modal
                isOpen={modalexiste}
                style={customStyles}
            >
                <div className='Modal'>
                    <h2 className='c-primario text-center'>Usuario ya Existe</h2>
                    <button onClick={() => setModalexiste(!modalexiste)} className='btn btn-primary pt-2 pb-2 p-4 m-auto d-block mt-4'>Seguir</button>
                </div>
            </Modal>
            <Modal
                isOpen={modalfalta}
                style={customStyles}
            >
                <div className='Modal'>
                    <h2 className='c-primario text-center'>Faltan Campos</h2>
                    <button onClick={() => setModalfalta(!modalfalta)} className='btn btn-primary pt-2 pb-2 p-4 m-auto d-block mt-4'>Seguir</button>
                </div>
            </Modal>
            <Modal
                isOpen={modaldigitos}
                style={customStyles}
            >
                <div className='Modal'>
                    <h2 className='c-primario text-center'>Minimo 6 caracter en la contraseña</h2>
                    <button onClick={() => setModaldigitos(!modaldigitos)} className='btn btn-primary pt-2 pb-2 p-4 m-auto d-block mt-4'>Seguir</button>
                </div>
            </Modal>
            <div className="bg-primario w-100 header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                                <img src={Logo} className="logo" />
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <a onClick={handleInicio} className="nav-link" href="#">
                                            <i className="fas fa-house"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={handleTienda} className="nav-link" href="#">
                                            Tienda
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={handleLogin} className="nav-link active">
                                            Login
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='loginApp'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'></div>
                        <div className='col-md-6'>
                            <div className='card p-4'>
                                <div className='card-body'>
                                    <h3 className='text-center'>Regístrate</h3>
                                    <form onSubmit={handlerRegistro}>
                                        <div className="mb-3">
                                            <label className="form-label">Correo: </label>
                                            <input type="email" className="form-control" id='email' />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Contraseña: </label>
                                            <input type="password" className="form-control" id="password" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Nombre y Apellido: </label>
                                            <input type="text" className="form-control" id='user' />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Teléfono: </label>
                                            <input type="number" className="form-control" id='telefono' />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">DNI: </label>
                                            <input type="number" className="form-control" id='dni' />
                                        </div>
                                        <button type="submit" className="btn btn-primary w-100 mt-4">Registrarme</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}