import Logo from './../../img/logo.png'
import ProductoImg from './../../img/motor.png'
import Descarga from './../../img/descarga.jpg'
import BannerUno from './../../img/bannerUno.jpg'
import BannerDos from './../../img/bannerDos.jpeg'
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate, useParams } from 'react-router'
import { Stepper, Step } from "react-form-stepper";
import { useAuth } from '../../authContext/authContext'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";

import { EffectFade, Autoplay, Pagination, Navigation, FreeMode } from "swiper";
import { async } from '@firebase/util'


export function Carrito() {


    const navigate = useNavigate()


    const [botoncerrar, setBotoncerrar] = useState(false);

    const { logout, user } = useAuth();
    console.log(user)
    const handleCerrarsesion = async () => {
        try{
            await logout();
        }catch (error){
            console.error(error.message)
        }
    }

    const handleTienda = () => {
        navigate('/Tienda')
    }
    const handleInicio = () => {
        navigate('/Inicio')
    }
    const handleDetalle = () => {
        navigate('/Detalle-del-producto')
    }
    const handleCarrito = () => {
        navigate('/Carrito')
    }
    const handlePagos = () => {
        navigate('/Pagos')
    }

    return (
        <>
            <div className="bg-primario w-100 header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                                <img src={Logo} className="logo" />
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={handleInicio}>
                                            <i className="fas fa-house"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={handleTienda}>
                                            Tienda
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" onClick={handleCarrito}>
                                            <span class="position-absolute translate-middle badge rounded-pill bg-danger">
                                                99
                                            </span>
                                            <i className="fas fa-shopping-bag"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={() => { setBotoncerrar(!botoncerrar) }}>
                                            <i className="fas fa-user"></i>
                                        </a>
                                        {
                                            botoncerrar === true ?
                                                (
                                                    <div className='cerrarsesionmodal' onClick={handleCerrarsesion}>
                                                        <p className='m-0'>
                                                            Cerrar Sesión
                                                        </p>
                                                    </div>
                                                ) :
                                                <></>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='row pt-5'>
                    <div className='col-md-8'>
                        <div className='caja-edit-product'>
                            <div className='item-img'>
                                <img src={ProductoImg} />
                            </div>
                            <div className='card-body overflow-hidden'>
                                <h6>Apple iPhone 11 (64GB, Black)</h6>
                                <p className='descripcion-carrito'>The Apple iPhone 11 is a great smartphone, which was loaded with a lot of quality features. It comes with a waterproof and dustproof body which is the key attraction of the device. The excellent set of cameras offer excellent images as well as capable of recording crisp videos. However, expandable storage and a fingerprint scanner would have made it a perfect option to go for around this price range.</p>
                                <div className='contador'>
                                    <div className='boton'>
                                        +
                                    </div>
                                    <div className='total'>
                                        1
                                    </div>
                                    <div className='boton'>
                                        -
                                    </div>
                                </div>
                            </div>
                            <div className='item-options'>
                                <h4 className='c-primario text-center'>
                                    $339.99
                                </h4>
                                <button className='btn btn-primary mt-3 w-100'>Remover</button>
                            </div>
                        </div>
                        <div className='caja-edit-product'>
                            <div className='item-img'>
                                <img src={ProductoImg} />
                            </div>
                            <div className='card-body overflow-hidden'>
                                <h6>Apple iPhone 11 (64GB, Black)</h6>
                                <p className='descripcion-carrito'>The Apple iPhone 11 is a great smartphone, which was loaded with a lot of quality features. It comes with a waterproof and dustproof body which is the key attraction of the device. The excellent set of cameras offer excellent images as well as capable of recording crisp videos. However, expandable storage and a fingerprint scanner would have made it a perfect option to go for around this price range.</p>
                                <div className='contador'>
                                    <div className='boton'>
                                        +
                                    </div>
                                    <div className='total'>
                                        1
                                    </div>
                                    <div className='boton'>
                                        -
                                    </div>
                                </div>
                            </div>
                            <div className='item-options'>
                                <h4 className='c-primario text-center'>
                                    $339.99
                                </h4>
                                <button className='btn btn-primary mt-3 w-100'>Remover</button>
                            </div>
                        </div>
                        <div className='caja-edit-product'>
                            <div className='item-img'>
                                <img src={ProductoImg} />
                            </div>
                            <div className='card-body overflow-hidden'>
                                <h6>Apple iPhone 11 (64GB, Black)</h6>
                                <p className='descripcion-carrito'>The Apple iPhone 11 is a great smartphone, which was loaded with a lot of quality features. It comes with a waterproof and dustproof body which is the key attraction of the device. The excellent set of cameras offer excellent images as well as capable of recording crisp videos. However, expandable storage and a fingerprint scanner would have made it a perfect option to go for around this price range.</p>
                                <div className='contador'>
                                    <div className='boton'>
                                        +
                                    </div>
                                    <div className='total'>
                                        1
                                    </div>
                                    <div className='boton'>
                                        -
                                    </div>
                                </div>
                            </div>
                            <div className='item-options'>
                                <h4 className='c-primario text-center'>
                                    $339.99
                                </h4>
                                <button className='btn btn-primary mt-3 w-100'>Remover</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='cardd'>
                            <h5 className='text-center c-primario'>
                                Detalles de tu lista
                            </h5>
                            <hr />
                            <div className='tabla-precio'>
                                <div className='overflow-hidden'>
                                    <p>
                                        Apple iPhone 11 (64GB, Black)
                                    </p>
                                    <p>
                                        Apple iPhone 12 (64GB, Black)
                                    </p>
                                </div>
                                <div>
                                    <div className='d-flex flex-row-reverse flex-column'>
                                        <p>
                                            $339.99
                                        </p>
                                    </div>
                                    <div className='d-flex flex-row-reverse flex-column'>
                                        <p>
                                            $439.99
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className='tabla-precio'>
                                <div className='overflow-hidden'>
                                        <p className='m-0 fw-bold c-primario'>
                                            Total:
                                        </p>
                                </div>
                                <div>
                                    <div className='d-flex flex-row-reverse flex-column'>
                                        <p className='m-0 fw-bold'>
                                            $339.99
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <button className='btn btn-primary w-100' onClick={handlePagos}>
                                Realizar Pago
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}