import Logo from './../img/logo.png'
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router'
import { useAuth } from '../authContext/authContext'
import Modal from 'react-modal';

export function Login() {

    const navigate = useNavigate()

    const handleRegistro = () => {
        navigate('/Registro')
    }
    const handleInicio = () => {
        navigate('/')
    }
    const handleTienda = () => {
        navigate('/Vista-Tienda')
    }
    const handleInicioLogin = () => {
        navigate('/Inicio')
    }

    const [correo, setCorreo] = useState(false);
    const [contrasena, setContrasena] = useState(false);

    const { login } = useAuth();

    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    const [error, setError] = useState("");

    const handleChange = ({ target: { name, value } }) => {
        setUser({ ...user, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(user)
        setError("");
        try {
            await login(user.email, user.password);
            navigate("/Inicio");
        } catch (error) {
            setError(error.message);
        }
    }

    const handleAlerta = (item) => {
        console.log(item)
        if (item === "Firebase: Error (auth/user-not-found).") {
            return (
                <p className='text-danger text-center mt-4'>
                    Correo Errado
                </p>
            );
        }
        if (item === "Firebase: Error (auth/wrong-password).") {
            return(
                <p className='text-danger text-center mt-4'>
                    Contraseña equivocada
                </p>
            )
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };


    return (
        <>
            <div className="bg-primario w-100 header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                                <img src={Logo} className="logo" />
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <a onClick={handleInicio} className="nav-link" href="#">
                                            <i className="fas fa-house"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={handleTienda}>
                                            Tienda
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active">
                                            Login
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='loginApp'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'></div>
                        <div className='col-md-6'>
                            <div className='card p-4'>
                                <div className='card-body'>
                                    <h3 className='text-center'>Iniciar Sesión</h3>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Correo</label>
                                            <input type="email" className="form-control" onChange={handleChange} name='email' required />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Contraseña</label>
                                            <input type="password" className="form-control" onChange={handleChange} name='password' required />
                                        </div>
                                        <button type="submit" className="btn btn-primary w-100 mt-4">Entrar</button>
                                        <a onClick={handleRegistro} className='registrateLogin'>
                                            Registrate
                                        </a>
                                        {
                                            handleAlerta(error)
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}