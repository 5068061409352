import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Inicio } from './storage/vistas/Inicio'
import { Login } from './storage/vistas/Login'
import { Registro } from './storage/vistas/Registro'
import { Tienda } from './storage/vistas/Tienda'
import { Detalle } from './storage/vistas/Detalle'
import { InicioLogin } from './storage/vistas/vistasLogin/InicioLogin'
import { TiendaLogin } from './storage/vistas/vistasLogin/TiendaLogin'
import { DetalleLogin } from './storage/vistas/vistasLogin/DetalleLogin'
import { Carrito } from './storage/vistas/vistasLogin/Carrito'
import { Pagos } from './storage/vistas/vistasLogin/Pagos'
import { AuthProvider } from "./storage/authContext/authContext"
import { ProtectedRoute } from "./storage/vistas/ProtectedRoute"

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Inicio />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/Registro' element={<Registro />} />
          <Route path='/Vista-Tienda' element={<Tienda />} />
          <Route path='/Detalle' element={<Detalle />} />


          <Route path='/Inicio' element={
            <ProtectedRoute>
              <InicioLogin />
            </ProtectedRoute>
          } />
          <Route path='/Tienda' element={
            <ProtectedRoute>
              <TiendaLogin />
            </ProtectedRoute>
          } />
          <Route path='/Detalle-del-producto' element={
            <ProtectedRoute>
              <DetalleLogin />
            </ProtectedRoute>
          } />
          <Route path='/Carrito' element={
            <ProtectedRoute>
              <Carrito />
            </ProtectedRoute>
          } />
          <Route path='/Pagos' element={
            <ProtectedRoute>
              <Pagos />
            </ProtectedRoute>
          } />
          <Route path='*' element={
            <ProtectedRoute>
              <Inicio />
            </ProtectedRoute>
          } />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
