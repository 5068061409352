import Logo from './../../img/logo.png'
import ProductoImg from './../../img/motor.png'
import Descarga from './../../img/descarga.jpg'
import BannerUno from './../../img/bannerUno.jpg'
import BannerDos from './../../img/bannerDos.jpeg'
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate, useParams } from 'react-router'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";

import { EffectFade, Autoplay, Pagination, Navigation, FreeMode } from "swiper";

export function TiendaLogin() {

    const navigate = useNavigate()


    const [botoncerrar, setBotoncerrar] = useState(false);

    const handleCerrarsesion = () => {
        navigate('/')
    }

    const handleTienda = () => {
        navigate('/Tienda')
    }
    const handleInicio = () => {
        navigate('/Inicio')
    }
    const handleDetalle = () => {
        navigate('/Detalle-del-producto')
    }
    const handleCarrito = () =>{
        navigate('/Carrito')
    }

    return (
        <>
            <div className="bg-primario w-100 header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                                <img src={Logo} className="logo" />
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={handleInicio}>
                                            <i className="fas fa-house"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" onClick={handleTienda}>
                                            Tienda
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={handleCarrito}>
                                            <span class="position-absolute translate-middle badge rounded-pill bg-danger">
                                                99
                                            </span>
                                            <i className="fas fa-shopping-bag"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={() => { setBotoncerrar(!botoncerrar) }}>
                                            <i className="fas fa-user"></i>
                                        </a>
                                        {
                                            botoncerrar === true ?
                                                (
                                                    <div className='cerrarsesionmodal' onClick={handleCerrarsesion}>
                                                        <p className='m-0'>
                                                            Cerrar Sesión
                                                        </p>
                                                    </div>
                                                ) :
                                                <></>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='shop'>
                <h2 className='text-center mt-5 mb-5'>
                    Nuestros Productos
                </h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='cardd'>
                                <div className='card-body'>
                                    <h5>
                                        Filtrado
                                    </h5>
                                    <div className='quitarfiltro'>
                                        <i class="fas fa-times"></i>
                                        Guayas
                                    </div>
                                    <div className='quitarfiltro'>
                                        <i class="fas fa-times"></i>
                                        Guayaquil
                                    </div>
                                    <div className='quitarfiltro'>
                                        <i class="fas fa-times"></i>
                                        Ford
                                    </div>
                                    <div className='quitarfiltro'>
                                        <i class="fas fa-times"></i>
                                        Fiesta
                                    </div>
                                    <div className='filtrocategoria mt-3'>
                                        <h5>
                                            Categoria
                                        </h5>
                                        <form>
                                            <div class="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioDisabled" />
                                                <label className="form-check-label">
                                                    Motor
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioCheckedDisabled" />
                                                <label className="form-check-label">
                                                    Vidrios
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioCheckedDisabled" />
                                                <label className="form-check-label">
                                                    Frenos
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioCheckedDisabled" />
                                                <label className="form-check-label">
                                                    Accesorios
                                                </label>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='limpiarTodo mt-3'>
                                        <button className='btn btn-primary w-100'>
                                            Limpiar todo el filtro
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='card w-100'>
                                        <a onClick={handleDetalle}>
                                            <img src={ProductoImg} className='' />
                                        </a>
                                        <div className='card-body'>
                                            <div>
                                                <h6 className='text-end fw-bold'>
                                                    $ 339.99
                                                </h6>
                                            </div>
                                            <a onClick={handleDetalle}>
                                                <h5 className="text-start titulo">
                                                    Titulo del producto
                                                </h5>
                                            </a>
                                            <p className="text-start">
                                                una breve descripcion del producto a precentar
                                            </p>
                                        </div>
                                        <div className='d-flex loginapp'>
                                            <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Comprar
                                            </div>
                                            <div className='verProducto w-50 pt-2 pb-2 text-white fs-5' onClick={handleDetalle}>
                                                Ver Producto
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card w-100'>
                                        <a href='#'>
                                            <img src={ProductoImg} className='' />
                                        </a>
                                        <div className='card-body'>
                                            <div>
                                                <h6 className='text-end fw-bold'>
                                                    $ 339.99
                                                </h6>
                                            </div>
                                            <a href='#'>
                                                <h5 className="text-start">
                                                    Titulo del producto
                                                </h5>
                                            </a>
                                            <p className="text-start">
                                                una breve descripcion del producto a precentar
                                            </p>
                                        </div>
                                        <div className='d-flex loginapp'>
                                            <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Comprar
                                            </div>
                                            <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Ver Producto
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card w-100'>
                                        <a href='#'>
                                            <img src={ProductoImg} className='' />
                                        </a>
                                        <div className='card-body'>
                                            <div>
                                                <h6 className='text-end fw-bold'>
                                                    $ 339.99
                                                </h6>
                                            </div>
                                            <a href='#'>
                                                <h5 className="text-start">
                                                    Titulo del producto
                                                </h5>
                                            </a>
                                            <p className="text-start">
                                                una breve descripcion del producto a precentar
                                            </p>
                                        </div>
                                        <div className='d-flex loginapp'>
                                            <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Comprar
                                            </div>
                                            <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Ver Producto
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card w-100'>
                                        <a href='#'>
                                            <img src={ProductoImg} className='' />
                                        </a>
                                        <div className='card-body'>
                                            <div>
                                                <h6 className='text-end fw-bold'>
                                                    $ 339.99
                                                </h6>
                                            </div>
                                            <a href='#'>
                                                <h5 className="text-start">
                                                    Titulo del producto
                                                </h5>
                                            </a>
                                            <p className="text-start">
                                                una breve descripcion del producto a precentar
                                            </p>
                                        </div>
                                        <div className='d-flex loginapp'>
                                            <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Comprar
                                            </div>
                                            <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Ver Producto
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card w-100'>
                                        <a href='#'>
                                            <img src={ProductoImg} className='' />
                                        </a>
                                        <div className='card-body'>
                                            <div>
                                                <h6 className='text-end fw-bold'>
                                                    $ 339.99
                                                </h6>
                                            </div>
                                            <a href='#'>
                                                <h5 className="text-start">
                                                    Titulo del producto
                                                </h5>
                                            </a>
                                            <p className="text-start">
                                                una breve descripcion del producto a precentar
                                            </p>
                                        </div>
                                        <div className='d-flex loginapp'>
                                            <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Comprar
                                            </div>
                                            <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Ver Producto
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card w-100'>
                                        <a href='#'>
                                            <img src={ProductoImg} className='' />
                                        </a>
                                        <div className='card-body'>
                                            <div>
                                                <h6 className='text-end fw-bold'>
                                                    $ 339.99
                                                </h6>
                                            </div>
                                            <a href='#'>
                                                <h5 className="text-start">
                                                    Titulo del producto
                                                </h5>
                                            </a>
                                            <p className="text-start">
                                                una breve descripcion del producto a precentar
                                            </p>
                                        </div>
                                        <div className='d-flex loginapp'>
                                            <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Comprar
                                            </div>
                                            <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Ver Producto
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card w-100'>
                                        <a href='#'>
                                            <img src={ProductoImg} className='' />
                                        </a>
                                        <div className='card-body'>
                                            <div>
                                                <h6 className='text-end fw-bold'>
                                                    $ 339.99
                                                </h6>
                                            </div>
                                            <a href='#'>
                                                <h5 className="text-start">
                                                    Titulo del producto
                                                </h5>
                                            </a>
                                            <p className="text-start">
                                                una breve descripcion del producto a precentar
                                            </p>
                                        </div>
                                        <div className='d-flex loginapp'>
                                            <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Comprar
                                            </div>
                                            <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Ver Producto
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card w-100'>
                                        <a href='#'>
                                            <img src={ProductoImg} className='' />
                                        </a>
                                        <div className='card-body'>
                                            <div>
                                                <h6 className='text-end fw-bold'>
                                                    $ 339.99
                                                </h6>
                                            </div>
                                            <a href='#'>
                                                <h5 className="text-start">
                                                    Titulo del producto
                                                </h5>
                                            </a>
                                            <p className="text-start">
                                                una breve descripcion del producto a precentar
                                            </p>
                                        </div>
                                        <div className='d-flex loginapp'>
                                            <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Comprar
                                            </div>
                                            <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Ver Producto
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card w-100'>
                                        <a href='#'>
                                            <img src={ProductoImg} className='' />
                                        </a>
                                        <div className='card-body'>
                                            <div>
                                                <h6 className='text-end fw-bold'>
                                                    $ 339.99
                                                </h6>
                                            </div>
                                            <a href='#'>
                                                <h5 className="text-start">
                                                    Titulo del producto
                                                </h5>
                                            </a>
                                            <p className="text-start">
                                                una breve descripcion del producto a precentar
                                            </p>
                                        </div>
                                        <div className='d-flex loginapp'>
                                            <div className='comprarProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Comprar
                                            </div>
                                            <div className='verProducto w-50 pt-2 pb-2 text-white fs-5'>
                                                Ver Producto
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer mt-5'>
                    <a href='www.alucinamkt.com'>
                        <p className='m-0 '>
                            © 2022 — Derechos reservados  AlucinaMKT
                        </p>
                    </a>
                </div>
            </div>
        </>
    )
}