import Logo from './../../img/logo.png'
import ProductoImg from './../../img/motor.png'
import Descarga from './../../img/descarga.jpg'
import BannerUno from './../../img/bannerUno.jpg'
import BannerDos from './../../img/bannerDos.jpeg'
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate, useParams } from 'react-router'
import { Stepper, Step } from "react-form-stepper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";

import { EffectFade, Autoplay, Pagination, Navigation, FreeMode } from "swiper";


export function Pagos() {

    const navigate = useNavigate()


    const [botoncerrar, setBotoncerrar] = useState(false);

    const handleCerrarsesion = () => {
        navigate('/')
    }

    const handleTienda = () => {
        navigate('/Tienda')
    }
    const handleCarrito = () => {
        navigate('/Carrito')
    }
    const handleInicio = () => {
        navigate('/Inicio')
    }

    const [goSteps, setGoSteps] = useState(0);

    return (
        <>
            <div className="bg-primario w-100 header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                                <img src={Logo} className="logo" />
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={handleInicio}>
                                            <i className="fas fa-house"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={handleTienda}>
                                            Tienda
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" onClick={handleCarrito}>
                                            <span class="position-absolute translate-middle badge rounded-pill bg-danger">
                                                99
                                            </span>
                                            <i className="fas fa-shopping-bag"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={() => { setBotoncerrar(!botoncerrar) }}>
                                            <i className="fas fa-user"></i>
                                        </a>
                                        {
                                            botoncerrar === true ?
                                                (
                                                    <div className='cerrarsesionmodal' onClick={handleCerrarsesion}>
                                                        <p className='m-0'>
                                                            Cerrar Sesión
                                                        </p>
                                                    </div>
                                                ) :
                                                <></>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='row pt-5'>
                    <div className='col-12'>
                        <h3 className='text-center mb-5 c-primario fw-bold'>
                            Finalizar Compra
                        </h3>
                    </div>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <div>
                            <Stepper activeStep={goSteps}>
                                <Step onClick={() => setGoSteps(0)} label="Lista de Productos" />
                                <Step onClick={() => setGoSteps(1)} label="Información" />
                                <Step onClick={() => setGoSteps(2)} label="Pagar" />
                            </Stepper>
                            {goSteps === 0 && (
                                <div className='cardd p-5'>
                                    <h5 className='text-center c-primario'>
                                        Detalles de tu lista
                                    </h5>
                                    <hr />
                                    <div className='tabla-precio'>
                                        <div className='overflow-hidden'>
                                            <p>
                                                Apple iPhone 11 (64GB, Black)
                                            </p>
                                            <p>
                                                Apple iPhone 12 (64GB, Black)
                                            </p>
                                        </div>
                                        <div>
                                            <div className='d-flex flex-row-reverse flex-column'>
                                                <p>
                                                    $339.99
                                                </p>
                                            </div>
                                            <div className='d-flex flex-row-reverse flex-column'>
                                                <p>
                                                    $439.99
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='tabla-precio'>
                                        <div className='overflow-hidden'>
                                            <p className='m-0 fw-bold c-primario'>
                                                Total:
                                            </p>
                                        </div>
                                        <div>
                                            <div className='d-flex flex-row-reverse flex-column'>
                                                <p className='m-0 fw-bold'>
                                                    $339.99
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <button className="btn btn-secondary w-100 mb-3" onClick={handleCarrito}>
                                        Editar
                                    </button>
                                    <button className="btn btn-primary w-100" onClick={() => setGoSteps(1)}>
                                        Aceptar
                                    </button>
                                </div>
                            )}
                            {goSteps === 1 && (
                                <div className='cardd p-5'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>
                                                Nombre y Apellido:
                                            </label>
                                            <input type='text' className='form-control mb-4 mt-1' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label>
                                                Teléfono:
                                            </label>
                                            <input type='text' className='form-control mb-4 mt-1' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label>
                                                DNI:
                                            </label>
                                            <input type='text' className='form-control mb-4 mt-1' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label>
                                                Ciudad:
                                            </label>
                                            <select className="form-select mb-4 mt-1" aria-label="Default select example">
                                                <option selected></option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div className='col-12'>
                                            <label>
                                                Ubicación exacta:
                                            </label>
                                            <textarea className='form-control mb-4 mt-1' >

                                            </textarea>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary w-100" onClick={() => setGoSteps(2)}>Siguiente</button>
                                </div>
                            )}
                            {goSteps === 2 && (
                                <div className='cardd p-5'>
                                    
                                    <button className='btn btn-primary w-100' onClick={() => setGoSteps(3)}>Pagar</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}