import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase/firebase";

export const authContext = createContext()

export const useAuth = () => {

    const context = useContext(authContext);
    if (!context) throw new Error('is not provider')
    return context

}

export function AuthProvider({ children }) {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
  
    const login = (email, password) => {
      return signInWithEmailAndPassword(auth, email, password);
    };
    
    const logout = () => signOut(auth);
  
    useEffect(() => {
      const unsubuscribe = onAuthStateChanged(auth, (currentUser) => {
        console.log({ currentUser });
        setUser(currentUser);
        setLoading(false);
      });
      return () => unsubuscribe();
    }, []);

    return (
        <authContext.Provider
            value={{
                login,
                user,
                logout,
                loading,
            }}
        >
            {children}
        </authContext.Provider>
    );

}